import React from 'react';
import {
  Grid,
} from '@material-ui/core'
import ParticlesBg from 'particles-bg'
import Terminal from '../components/terminal'

import './index.css'

const Layout = () => (
  <Grid 
    onClick={() => {
      document.getElementsByClassName("terminalInput")[0].children[0].children[1].focus()
    }}
    container
    className="container"
    justify="center"
    alignItems="center"
  >
    <Terminal />
    <ParticlesBg colors={["#a3ffb0"]} num={20} type="lines" bg={true} />
  </Grid>
)

export default Layout;