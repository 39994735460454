import React, { Fragment } from 'react';
import { AlertTriangle, ArrowRightCircle } from 'react-feather';
import { Grid } from '@material-ui/core';

const Help = ({ props }) => (
  <Fragment>
    <br/>
    <Grid alignItems='center'>
      <AlertTriangle color='red' />
      &nbsp;
      <Fragment>
      Type a command in the list into the area labeled ">\" to execute it
      </Fragment>
      &nbsp;
      <AlertTriangle color='red' />
    </Grid>

    <Grid container alignItems="center">
      <ArrowRightCircle  color='red'/>
      &nbsp;
      <p>'>\projects': List interesting projects I've worked on</p>
    </Grid>
    <Grid container alignItems="center">
      <ArrowRightCircle  color='red'/>
      &nbsp;
      <p>'>\research': List published blockchain research papers</p>
    </Grid>
    <Grid container alignItems="center">
      <ArrowRightCircle  color='red'/>
      &nbsp;
      <p>'>\contact': Get contact information</p>
    </Grid>
    <Grid container alignItems="center">
      <ArrowRightCircle  color='red'/>
      &nbsp;
      <p>'>\help': Prints help text containing commands</p>
    </Grid>
    <Grid container alignItems="center">
      <ArrowRightCircle  color='red'/>
      &nbsp;
      <p>'>\intro': Prints intro text</p>
    </Grid>
    <Grid container alignItems="center">
      <ArrowRightCircle  color='red'/>
      &nbsp;
      <p>'>\clear': Clears the screen</p>
    </Grid>
  </Fragment>
); 

export default Help
