import React from 'react';
import { ReactTerminal, ReactOutputRenderers, ReactThemes }  from 'react-terminal-component';
import {
  CommandMapping, OutputFactory, EmulatorState,
  defaultCommandMapping, Outputs
} from 'javascript-terminal';

import Contact from '../contact'
import Intro from '../intro'
import Print from '../print'
import Help from '../help'
import Github from '../github'
import Research from '../research'

import './index.css'

// Generic create record for defining components to output
const createRecord = (type, data) => (
  new OutputFactory.OutputRecord({
    type,
    content: data
  })
)

// Create the default terminal state
const defaultState = EmulatorState.createEmpty();
const defaultOutputs = defaultState.getOutputs();
const newOutputs = Outputs.addRecord(
  defaultOutputs, createRecord('intro', { body: '123455'} )
);

// Adding custom commands
const customState = EmulatorState.create({
  commandMapping: CommandMapping.create({
    clear: defaultCommandMapping.clear,
    intro: {
      function: (state, opts) => {
        const userInput = opts.join(' ');

        return {
          output: createRecord('intro', {  } ),
        };
      },
      'optDef': {}
    },
    print: {
      function: (state, opts) => {
        const userInput = opts.join(' ');

        return {
          output: createRecord('print', { title: userInput } ),
        };
      },
      'optDef': {}
    },
    contact: {
      function: (state, opts) => {
        return {
          output: createRecord('contact', { body: 'paddymchale@hotmail.com' } ),
        };
      },
      'optDef': {}
    },
    help: {
      function: (state, opts) => {
        return {
          output: createRecord('help', { body: '' } ),
        };
      },
      'optDef': {}
    },
    projects: {
      function: (state, opts) => {
        return {
          output: createRecord('projects', { body: '' } ),
        };
      },
      'optDef': {}
    },
    research: {
      function: (state, opts) => {
        return {
          output: createRecord('research', { body: '' } ),
        };
      },
      'optDef': {}
    }
  }),
  outputs: newOutputs
});

const Terminal = props => (
  <ReactTerminal 
    inputStr='help'
    theme={{
      borderRadius: '25px',
      ...ReactThemes.hacker,
      width: '50%',
      height: '80vh',
      fontSize: '1.6rem',
    }}
    outputRenderers={{
      ...ReactOutputRenderers,
      ['intro']: Intro,
      ['print']: Print,
      ['contact']: Contact,
      ['projects']: Github,
      ['research']: Research,
      ['help']: Help,
    }}
    emulatorState={customState}
    promptSymbol='>\'
  />
)

export default Terminal;
