import React from 'react';
import {
  Grid,
} from '@material-ui/core'
import { Mail } from 'react-feather';

const Contact = ({ content }) => (
  <Grid container alignItems="center">
    <a href="mailto:paddymchale@hotmail.com">
      <h3>
        Feel free to email me @ <a href="mailto:paddymchale@hotmail.com">{content.body}</a>
        &nbsp;
        <Mail color='red'/>
      </h3>
    </a>
  </Grid>
); 

export default Contact
