import React, { Fragment } from 'react';
import {
  Grid,
} from '@material-ui/core'
import { Codesandbox } from 'react-feather';

const Github = ({ props }) => (
  <Fragment>
    <a target="_blank" href="https://github.com/PaddyMc">
      <Grid container alignItems="center">
        <Codesandbox color='red'/>
        &nbsp;
        <Grid>
        <h3>Paddy Mc</h3>
        <p>My personal github profile</p>
        </Grid>
      </Grid>
    </a>
    <a target="_blank" href="https://github.com/elesto-dao/elesto">
      <Grid container alignItems="center">
        <Codesandbox color='red'/>
        &nbsp;
        <Grid>
        <h3>Elesto</h3>
        <p>An SSI protocol built using the Cosmos SDK</p>
        </Grid>
      </Grid>
    </a>
    <a target="_blank" href="https://github.com/brickblock-io/smart-contracts">
      <Grid container alignItems="center">
        <Codesandbox color='red'/>
        &nbsp;
        <Grid>
        <h3>Brickblock smart contracts</h3>
        <p>Smart contract for real estate deployed on Ethereum</p>
        </Grid>
      </Grid>
    </a>
    <a target="_blank" href="https://github.com/PaddyMc/did-solana">
      <Grid container alignItems="center">
        <Codesandbox color='red'/>
        &nbsp;
        <Grid>
        <h3>DID Solana</h3>
        <p>Smart contract written in Rust and deployed on Solana</p>
        </Grid>
      </Grid>
    </a>
    <a target="_blank" href="https://github.com/blockchain-abstraction-middleware">
      <Grid container alignItems="center">
        <Codesandbox color='red'/>
        &nbsp;
        <Grid>
        <h3>Blockchain Abstraction Middleware</h3>
        <p>Middleware for Ethereum using K8S and terraform</p>
        </Grid>
      </Grid>
    </a>
    <a target="_blank" href="https://github.com/IPFS-Media-Platform">
      <Grid container alignItems="center">
        <Codesandbox color='red'/>
        &nbsp;
        <Grid>
        <h3>IPFS Media Platform</h3>
        <p>Media platform based on IPFS using EVM smart contracts</p>
        </Grid>
      </Grid>
    </a>
  </Fragment>
); 

export default Github
