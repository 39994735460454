import React, { Fragment } from 'react';
import {
  Grid,
} from '@material-ui/core'
import { File } from 'react-feather';

const Research = ({ props }) => (
  <Fragment>
    <a target="_blank" href="https://ieeexplore.ieee.org/document/10087121">
      <Grid container alignItems="center">
        <File color='red' />
        &nbsp;
        <Grid>
        <h3>Elesto: A Self Soverign Identity protocol</h3>
        <p>Published by IEEE;</p>
        </Grid>
      </Grid>
    </a>
    <a target="_blank" href="https://ieeexplore.ieee.org/document/9881831">
      <Grid container alignItems="center">
        <File color='red' />
        &nbsp;
        <Grid>
        <h3>Cosmos Cash: A regulatory compliant e-money token</h3>
        <p>Published by IEEE;</p>
        </Grid>
      </Grid>
    </a>
  </Fragment>
); 

export default Research
