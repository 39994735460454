import React, { Fragment } from 'react';
import {
  Grid,
} from '@material-ui/core'
import { Mail, Smile } from 'react-feather';

const Intro = ({ props }) => (
  <Fragment>
    <h1>>\intro</h1>

    <Grid container alignItems="center">
    <p>Hello!</p>
      &nbsp;
        <Smile color="red"/>
    </Grid>

    <p>
      I'm PaddyMc a full-stack blockchain engineer!
      I've worked with multiple L1 blockchain solutions
      and multiple smart contract platforms. I have 
      real experience developing and running
      infrastructure that supports
      Ethereum (Solidity), Solana (Rust), the
      Cosmos-SDK (Golang) and CosmWasm (Rust).
    </p>
    <a href="mailto:paddymchale@hotmail.com">
     <Grid container alignItems="center">
        <p>Need help with your blockchain project, contact me!</p>
      &nbsp;
      <Mail color="red"></Mail>
      </Grid>

    </a>
    <br />

    <p>Please press the enter key to execute the '>\help' command:</p>
  </Fragment>
); 

export default Intro
